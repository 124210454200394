/**
 * Cleans the input by removing any non-numeric characters except dots and commas,
 * and replaces commas with dots to standardize decimal notation.
 *
 * @param {any} v - The input value to be cleaned.
 * @returns {string} - The cleaned input as a string.
 *
 * @example
 * onInputChange("12,345abc.67");
 * // Returns "12345.67"
 */
export function onInputChange(v: any) {
  const cleaned = v.replace(/[^0-9,.]/g, '');
  return cleaned.replace(/,/g, '.');
}

/**
 * Rounds a number to the specified number of decimal places.
 *
 * @param {number} value - The number to be rounded.
 * @param {number} [places=1] - The number of decimal places to round to (default is 1).
 * @returns {number} - The rounded number.
 *
 * @example
 * roundDecimal(12.3456, 2);
 * // Returns 12.35
 *
 * @example
 * roundDecimal(12.3);
 * // Returns 12.3 (defaults to 1 decimal place)
 */
export const roundDecimal = (value: number, places?: number): number => {
  const factor = Math.pow(10, places ?? 1);
  return Math.round(value * factor) / factor;
};

/**
 * Formats a number or string by ensuring it contains only digits and a decimal point,
 * and limits the decimal part to a specified number of digits.
 *
 * @param {string | number} val - The input value to format.
 * @param {number} [decimal=2] - The number of decimal places to retain (default is 2).
 * @returns {string} - The formatted number as a string.
 *
 * @example
 * formatDecimalNumber("12.3456", 2);
 * // Returns "12.34"
 *
 * @example
 * formatDecimalNumber(1000);
 * // Returns "1000"
 */
export const formatDecimalNumber = (
  val: string | number,
  decimal?: number,
): any => {
  if (typeof val === 'number') val = val?.toString();

  // Remove any non-digit and non-decimal point characters
  val = val.replace(/[^0-9.]/g, '');

  // Check if there's a decimal point in the input
  if (val.includes('.')) {
    const [integerPart, decimalPart] = val.split('.');

    // Keep the full integer part and limit the decimal part to two digits
    const formattedDecimal = decimalPart.slice(0, decimal ?? 2);

    // Return the formatted value as XXXX.XX
    return `${integerPart}.${formattedDecimal}`;
  }

  // Return the original input if no decimal point, or if it's already a valid number
  return val;
};
